import { useEffect } from "react";
import { useRouter } from "next/router";
// the types for this package do not exist yet
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useFrontChatBoot } from "front-chat-sdk/hooks";

const chatId = process.env.NEXT_PUBLIC_FRONT_CHAT_ID || "";

const useFrontChatManager = () => {
  const {
    frontChat,
    initialize,
    isInitialized,
  }: {
    frontChat: (command: string) => void;
    initialize: (options: {
      chatId: string;
      shouldHideExpandButton: boolean;
      welcomeMessageAppearance: string;
    }) => void;
    isInitialized: boolean;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  } = useFrontChatBoot(document.body) as {
    frontChat: (command: string) => void;
    initialize: (options: {
      chatId: string;
      shouldHideExpandButton: boolean;
      welcomeMessageAppearance: string;
    }) => void;
    isInitialized: boolean;
  };
  const router = useRouter();

  useEffect(() => {
    if (!initialize || !frontChat) {
      return;
    }

    if (router.pathname === "/renewal-offers") {
      if (!isInitialized) {
        initialize({
          chatId,
          shouldHideExpandButton: true,
          welcomeMessageAppearance: "always",
        });
      }
    } else if (isInitialized) {
      // Combine else and if to avoid unexpected if error
      frontChat("shutdown");
    }
  }, [isInitialized, initialize, frontChat, router.pathname]); // Remove chatId from dependency array
};

export default useFrontChatManager;
