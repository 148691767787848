/* eslint-disable @typescript-eslint/no-floating-promises */

import NavigationDrawer from "@/components/NavigationDrawer";
import TimelineIcon from "@mui/icons-material/Timeline";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import RenewalOffersOutlinedIcon from "@mui/icons-material/SystemSecurityUpdateGoodOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "@/components/Loader";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import useFrontChatManager from "@/hooks/useFrontChatManager";
import * as Styled from "./styles";

const WithNav = ({
  children,
  hasBackgroundColor = true,
  noSidePadding = false,
}: {
  children: JSX.Element;
  hasBackgroundColor?: boolean;
  noSidePadding?: boolean;
}) => {
  const { logout, user, isLoading } = useAuth0();
  const redirectUri =
    typeof window !== "undefined" ? window.location.origin : "";

  // Initialize Front Chat Manager
  useFrontChatManager();

  const links = [
    {
      name: "Reports",
      href: "/reports",
      icon: <TimelineIcon />,
    },
    {
      name: "Units",
      href: "/units",
      icon: <HomeWorkOutlinedIcon />,
    },
    {
      name: "Bulk Download",
      href: "/bulk-download",
      icon: <TaskOutlinedIcon />,
    },
    {
      name: "Renewal Offers",
      href: "/renewal-offers",
      icon: <RenewalOffersOutlinedIcon />,
    },
    {
      name: "Contact",
      href: "mailto:propertysupport@heyrenew.com",
      icon: <LiveHelpOutlinedIcon />,
    },
    {
      name: "Log out",
      onClick: () => {
        logout({
          logoutParams: {
            returnTo: redirectUri ? `${redirectUri}/logout` : "",
          },
        });
      },
      icon: <LogoutIcon />,
    },
  ];

  if (isLoading) {
    return <Loader />;
  }
  if (user && user.email) {
    return (
      <Styled.Main $background={hasBackgroundColor}>
        <NavigationDrawer links={links} />
        <Styled.ContainerWrapper $noSidePadding={noSidePadding}>
          <Styled.Container>{children}</Styled.Container>
        </Styled.ContainerWrapper>
      </Styled.Main>
    );
  }
  return null;
};

export default withAuthenticationRequired(WithNav, {
  onRedirecting: () => <Loader />,
  loginOptions: {
    appState: {
      returnTo:
        typeof window !== "undefined"
          ? window.location.pathname + window.location.search
          : "/",
    },
  },
});
